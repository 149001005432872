<template>
  <div>
    <div class="row px-5 pb-5  mb-5 bg-white">
            <div class="col-12">
                <p class="paymentTitle" >Payment method:</p>
            </div>

            <div class="col-12">
                <div class="row d-flex align-items-center">
            <div class="col-6 col-md-2 ">
                <img src="/images/paypal.png" alt="">
            </div>
            <div class="col-6 col-md-5 ">
                <b-form-input class="w-100" style="background:#F4F3F5; border:none;" placeholder="Email" v-model="user.email"></b-form-input>
            </div>
            <div class="col-6 col-md-4 ">
                <button class="btn px-3 py-1" style="background:#0FD552; color: #ffffff; border-radius: 22px; font-size: 16px;   "><i class="fa fa-lock mr-2 mt-1" aria-hidden="true"></i> Confirmed</button>
            </div>
            </div>
            </div>

            <div class="col-12 mt-3">
                <p class="limitation-value">Last payment withdrawal April 12, 2021, Basic Plan</p>
            </div>

            <div class="col-12 d-flex align-items-center mt-3">
                <button  @click="openInvoiceModal" class="btn px-3 py-2" style="background:#4d4950;border-radius:6px;color:#ffffff;">Download Invoices <span class="ml-2"><i class="fa fa-file-text-o" aria-hidden="true"></i></span></button>
                <button  class="btn px-3 py-2 ml-3" style="background:#FFFFFF;box-shadow: 1px 6px 12px -4px rgba(10, 4, 17, 0.15);border: 1px solid #535355;border-radius:6px;color:#535355;">Manage Methods </button>
            </div>
        </div>

        <!-- Modals -->
        <InvoiceModal ref="InvoiceDonwloadModalRef"/>
  </div>
</template>

<script>
import InvoiceModal from "./invoiceModal.vue"
export default {
    props: {
    UserData: Object,
  },
  components: {
    InvoiceModal
  },
data(){
    return {
            user:this.UserData
    }
},
methods:{
    openInvoiceModal(){
        this.$refs.InvoiceDonwloadModalRef.OpenModal()
    }
}
}
</script>

<style scoped>



.limitation-value{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 21px;

letter-spacing: 0.04em;

color: #B3AFB6
}
.paymentTitle{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
color: #292929;
}
</style>